import React, { Component } from 'react';
import XLSX from 'xlsx';
import firebase from './services/firebase';
import CreateTasting from './components/CreateTasting';

const GOOD_HITTER = '1';
const BAD_HITTER = '0';
const RESTART_WORD = 'nulstil';

const POINTS_DK = {
  country: 'land',
  grape: 'drue',
  producer: 'producent',
  region: 'region',
  year: 'årgang',
};

const processData = (dataString) => {
  const dataStringLines = dataString.split(/\r\n|\n/);
  const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);

  const list = [];
  for (let i = 1; i < dataStringLines.length; i++) {
    const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
    if (headers && row.length === headers.length) {
      const obj = {};
      for (let j = 0; j < headers.length; j++) {
        let d = row[j];
        if (d.length > 0) {
          if (d[0] === '"')
            d = d.substring(1, d.length - 1);
          if (d[d.length - 1] === '"')
            d = d.substring(d.length - 2, 1);
        }
        if (headers[j]) {
          obj[headers[j]] = d;
        }
      }

      // remove the blank rows
      if (Object.values(obj).filter(x => x).length > 0) {
        list.push(obj);
      }
    }
  }

  const countries = {};
  list.forEach((w) => {
    if (!countries[w.Land]) {
      countries[w.Land] = {
        grapes: [],
        name: w.Land,
        producers: [],
        regions: [],
      };
    }
    
    if (!countries[w.Land].grapes.includes(w.Druesort))
      countries[w.Land].grapes.push(w.Druesort);

    if (!countries[w.Land].producers.includes(w.Producent))
      countries[w.Land].producers.push(w.Producent);

    if (!countries[w.Land].regions.includes(w.Region))
      countries[w.Land].regions.push(w.Region);
  });

  return { data: countries, count: list.length };
}

const snapshotObjectToArray = (data) => {
  if (!data) {
    return null;
  }

  return Object.keys(data).map(k => ({ ...data[k], _k: k }))
}

export default class App extends Component {
  isGamePending = () => {
    const { game } = this.state;

    if (!game || !game.status)
      return false;

    return game.status.toLowerCase() === 'pending';
  }

  hasGameStarted = () => {
    const { game } = this.state;

    if (!game || !game.status)
      return false;

    return game.status.toLowerCase() === 'started';
  }

  endTasting = () => {
    const { game, points: pointSystem, tastings, users } = this.state;

    tastings.filter(t => t.status === 'active').forEach(t => {
      t.left.side = 'left';
      t.right.side = 'right';
      [t.left, t.right].forEach(q => {
        if (!q.answers) {
          return;
        }

        Object.keys(q.answers).forEach(k => {
          let points = 0;
          let hits = 0;
          let hitters = '';
          const a = q.answers[k];

          if (a.country === q.country) {
            points += pointSystem.country;
            hitters += GOOD_HITTER;
            hits++;
          } else {
            hitters += BAD_HITTER;
          }
 
          if (a.region === q.region) {
            points += pointSystem.region;
            hitters += GOOD_HITTER;
            hits++;
          } else {
            hitters += BAD_HITTER;
          }
 
          if (a.grape === q.grape) {
            points += pointSystem.grape;
            hitters += GOOD_HITTER;
            hits++;
          } else {
            hitters += BAD_HITTER;
          }
 
          if (a.producer === q.producer) {
            points += pointSystem.producer;
            hitters += GOOD_HITTER;
            hits++;
          } else {
            hitters += BAD_HITTER;
          }
 
          if (a.year === q.year) {
            points += pointSystem.year;
            hitters += GOOD_HITTER;
            hits++;
          } else {
            hitters += BAD_HITTER;
          }

          if (!users[k].points.tastings) {
            users[k].points.tastings = {};
          }

          if (!users[k].points.tastings[t._k]) {
            users[k].points.tastings[t._k] = {
              total: 0,
              hits: 0,
            };
          }

          users[k].points.tastings[t._k][q.side] = {};
          users[k].points.tastings[t._k][q.side].points = points;
          users[k].points.tastings[t._k][q.side].hits = hits;
          users[k].points.tastings[t._k][q.side].hitters = hitters;
          users[k].points.tastings[t._k].total += points;
          users[k].points.tastings[t._k].hits += hits;
          users[k].points.total += points;
          users[k].points.hits += hits;
        });
      });

    });

    firebase.database().ref('users').update(users);
    firebase.database().ref('tastings').child(game.nextTasting).update({
      status: 'completed'
    });
  }

  nextTasting = () => {
    const { game, tastings } = this.state;

    if ( !tastings || tastings.length === 0 || !game) {
      return false;
    }

    const prevTasting = game && game.nextTasting && tastings.find(t => t._k === game.nextTasting);
    const nextTasting = prevTasting ? tastings.find(t => t.num > prevTasting.num) : tastings[0];

    firebase.database().ref('game').update({
      nextTasting: nextTasting._k,
      prevTasting: prevTasting ? prevTasting._k : null,
      status: 'started',
    });

    firebase.database().ref('tastings').child(nextTasting._k).update({
      status: 'active'
    });
  }

  restartGame = () => {
    const { users } = this.state;
    firebase.database().ref('game').update({
      prevTasting: null,
      nextTasting: null,
      status: 'pending'
    });

    Object.keys(users).forEach(k => {
      users[k].ready = false;
      users[k].points = {
        total: 0,
        hits: 0,
        tastings: null,
      }
    });

    firebase.database().ref('users').update(users);

    firebase.database().ref('tastings').once('value').then(snapshot => {
      const data = snapshot.val();
      Object.keys(data).forEach(k => {
        data[k].status = 'pending';
        data[k].left.answers = null;
        data[k].right.answers = null;
      })
      firebase.database().ref('tastings').set(data);
    });

    this.setState({ restartInput: '' });
  }

  startGame = () => {
    this.nextTasting();
  }

  addPlayer = () => {
    const { users } = this.state;
    const newName = this.playerNameInputRef.current.value;
    const alreadyExists = Object.keys(users).find(k => users[k].name === newName);

    if (alreadyExists || !this.isGamePending())
      return false;

    const newUserRef = firebase.database().ref('users').push();
    newUserRef.set({
      hits: 0,
      points: 0,
      ready: false,
      name: newName,
    })

    this.playerNameInputRef.current.value = '';
  }

  removePlayer = (id) => {
    if (!this.isGamePending())
      return false;

    firebase.database().ref(`users/${id}`).remove();
  }

  deleteTasting = (id) => {
    firebase.database().ref('tastings').child(id).remove();
  }

  swapTastings = (num1, num2) => {
    firebase.database().ref('tastings').once('value').then(snapshot => {
      const data = snapshot.val();

      if (!data) {
        return false;
      }

      Object.entries(data).forEach(a => {
        const [k, d] = a
        let num = null;
        if (d.num === num1) {
          num = num2;
        } else if (d.num === num2) {
          num = num1;
        }
        if (num) {
          firebase.database().ref('tastings').child(k).update({
            ...d,
            num,
          });
        }
      });
    });
  }

  updateWineList = () => {
    if (!this.isGamePending())
      return false;

    const { wineUpload } = this.state;

    if (!wineUpload)
      return false;

    firebase.database().ref('countries').set(wineUpload);
  }

  updatePoints = () => {}

  state = {
    game: null,
    restartInput: '',
    points: null,
    tastings: null,
    wine: null,
    wineUpload: null,
    wineUploadCount: 0,
    users: null,
  };

  constructor(props) {
    super(props)
    this.playerNameInputRef = React.createRef();
  }

  getNextTasting = () => {
    const { game, tastings } = this.state;

    if (!game || !game.nextTasting || !tastings) {
      return null;
    }

    return tastings.find(t => t._k === game.nextTasting);
  }

  getPrevTasting = () => {
    const { game, tastings } = this.state;

    if (!game || !game.prevTasting || !tastings) {
      return null;
    }

    return tastings.find(t => t._k === game.prevTasting);
  }

  handleRestartInput = (e) => {
    this.setState({ restartInput: e.target.value });
  }

  handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: 'binary' });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
      const processedData = processData(data)
      this.setState({
        wineUpload: processedData.data,
        wineUploadCount: processedData.count,
      });
    };
    reader.readAsBinaryString(file);
  }

  componentDidMount() {
    const gameRef = firebase.database().ref('game');
    const pointsRef = firebase.database().ref('points');
    const tastingsRef = firebase.database().ref('tastings');
    const wineRef = firebase.database().ref('countries');
    const usersRef = firebase.database().ref('users');

    gameRef.on('value', (snapshot) => {
      if (!snapshot.val()) {
        this.setState({ game: null });
        return false;
      }

      const game = snapshot.val();
      this.setState({ game });
    });

    pointsRef.on('value', (snapshot) => {
      if (!snapshot.val()) {
        this.setState({ points: null });
        return false;
      }

      const points = snapshot.val();
      this.setState({ points });
    });

    tastingsRef.on('value', (snapshot) => {
      const tastings = snapshotObjectToArray(snapshot.val());

      if (tastings) {
        tastings.sort((a, b) => a.num - b.num);
      }

      this.setState({ tastings });
    });

    wineRef.on('value', (snapshot) => {
      if (!snapshot.val()) {
        this.setState({ wine: null });
        return false;
      }

      const data = snapshot.val();
      const wine = Object.keys(data).map(name => ({ ...data[name], name }))
      this.setState({ wine });
    });

    usersRef.on('value', (snapshot) => {
      this.setState({ users: snapshot.val() });
    });
  }

  renderScoreboard = () => {
    const { users, game } = this.state;
    const sortedUsers = snapshotObjectToArray(users);

    if (sortedUsers) {
      sortedUsers.sort((a, b) => b.points.total === a.points.total ? a.name - b.name : b.points.total - a.points.total);
    }

    const usersList = sortedUsers && sortedUsers.map((t, i) => (
      <div className="user" key={`player-${t._k}`}>
        <div className="num">{i + 1}</div>
        <div className="name">{t.name}{ this.isGamePending() && t.ready && (<span>klar!</span>)}</div>
        { game && game.status && game.status === 'pending' && (
          <div onClick={() => this.removePlayer(t.id)} className="action">Slet</div>
        )}
        <div className="hits">{t.points.hits}</div>
        <div className="points">{t.points.total}</div>
      </div>
    ));

    return (
      <div className="scoreboard">
        { usersList }
      </div>
    );
  }

  renderTastings = () => {
    const { tastings, users } = this.state;
    const usersLength = users ? Object.keys(users).length : 0;

    const tastingsList = tastings && tastings.map(t => (
      <div className={`tasting status--${t.status}`} key={`tasting-${t._k}`}>
        <div className="num">{t.num}</div>
        <div className="bottles">
          <div className="taste">
            <div className="side">(V)</div>
            <div className="bottle">{t.left.bottle}</div>
            { this.hasGameStarted() && (<div className="count">{t.left.answers ? Object.keys(t.left.answers).length : 0}/{usersLength}</div>) }
          </div>
          <div className="taste">
            <div className="side">(H)</div>
            <div className="bottle">{t.right.bottle}</div>
            { this.hasGameStarted() && (<div className="count">{t.right.answers ? Object.keys(t.right.answers).length : 0}/{usersLength}</div>) }
          </div>
        </div>
        { this.isGamePending() && (
          <div className="options">
            { t.num > 1 && (<button onClick={() => this.swapTastings(t.num, t.num-1)}>op</button>) }
            { t.num < tastings.length && (<button onClick={() => this.swapTastings(t.num, t.num+1)}>ned</button>) }
            <button onClick={() => this.deleteTasting(t._k)}>slet</button>
          </div>
        )}
      </div>
    ));

    return (
      <div className="tastings-list">
        { tastingsList }
      </div>
    );
  }

  pointChange = (e) => {
    const id = e && e.target && e.target.id;
    const value = e && e.target && Number(e.target.value);

    if (!id || !value)
      return false;

    if (this.hasGameStarted())
      return false;

    return firebase.database().ref(`points/${id}`).set(value);
  }

  renderPointSystem = () => {
    const { points } = this.state;
    const pointsList = points && Object.keys(points).map(k => (
      <div className="point-box" key={`points--${k}`}>
        { this.hasGameStarted() && (<div className="point-number">{ points[k] }</div>) }
        { this.isGamePending() && (<input onChange={this.pointChange} id={k} type="number" className="point-input" value={points[k]} />) }
        <div className="point-label">{ POINTS_DK[k] }</div>
      </div>
    ));
    return (
      <div className="point-system">
        { pointsList }
      </div>
    );
  }

  render() {
    const { restartInput, tastings, users, wine, wineUpload, wineUploadCount } = this.state;
    const currentTasting = this.getNextTasting();
    const disableEnd = currentTasting && currentTasting.status === 'active';
    const disableNext = currentTasting && currentTasting.status === 'completed';
    const usersReady = users && Object.keys(users).filter(k => users[k].ready).length;
    const isGamePending = this.isGamePending();
    const hasGameStarted = this.hasGameStarted();

    return (
      <div id="app">
        { isGamePending && ( 
          <div className="button-cluster">
            { users && <span>{ usersReady } af {Object.keys(users).length} spillere er klar.</span> }
            <button className="start-game" onClick={this.startGame}>Start spillet</button>
          </div>
        )}
        { hasGameStarted && ( 
          <div className="button-cluster">
            <input type="text" onChange={this.handleRestartInput} />
            <button
              className="restart-game"
              onClick={this.restartGame}
              disabled={restartInput !== RESTART_WORD}
            >
              Genstart spillet
            </button>
            <span>skriv '{ RESTART_WORD }' i feltet</span>
          </div>
        )}
        <h2>Stilling</h2>
        { this.renderScoreboard() }
        { isGamePending && ( 
          <div className="button-cluster">
            <input ref={this.playerNameInputRef} type="text" />
            <button onClick={this.addPlayer}>Tilføj deltager</button>
          </div>
        )}
        <h2>Smagninger</h2>
        { this.renderTastings() }
        { hasGameStarted && ( 
          <div className="button-cluster">
            <button onClick={this.endTasting} disabled={!disableEnd}>Afslut smagning</button>
            <button onClick={this.nextTasting} disabled={!disableNext}>Start næste smagning</button>
          </div>
        )}
        { isGamePending && ( 
          <div className="button-cluster">
            <CreateTasting data={wine} tastings={tastings} />
          </div>
        )}
        { isGamePending && <h2>Vinliste</h2> }
        { isGamePending && ( 
          <div className="button-cluster">
            <input type="file" accept=".csv,.xlsx,.xls" onChange={this.handleFileUpload} />
            { wineUpload && wineUploadCount > 0 && ( 
              <div className="upload-stats">
                <span>{ `Ny vinliste er klar til at blive indlæst. Der er ${wineUploadCount} vine i listen.` }</span>
                <button className="confirm-upload" onClick={this.updateWineList}>Indlæs vin</button>
              </div>
            )}
          </div>
        )}
        <h2>Pointsystem</h2>
        { this.renderPointSystem() }
      </div>
    );
  }
}
