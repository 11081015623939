import React, { Component } from 'react';
import firebase from '../services/firebase';

export default class CreateTasting extends Component {
  state = {
    left: {
      countryData: null,
    },
    right: {
      countryData: null,
    },
  };

  onCountrySelect = (e, s) => {
    if (s !== 'left' && s !== 'right')
      return false;

    const newCountry = e && e.target && e.target.value;
    
    if (!newCountry)
      return false;

    const { country } = this.state;

    if (newCountry === country)
      return false;

    const { data } = this.props;
    const countryData = data.find(c => c.name === newCountry);

    this.setState({
      [s]: {
        countryData,
      },
    })
  }

  handleSubmitForm = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const newTasting = {
      status: 'pending',
      left: {},
      right: {},
    };

    for (const [key, value] of formData) {
      const [ altKey, side ] = key.split('--');
      if (altKey && side && value !== '') {
        newTasting[side][altKey] = altKey === 'year' ? Number(value) : value;
      }
    }

    if (Object.keys(newTasting.left).length !== 6)
      return false;

    if (Object.keys(newTasting.right).length !== 6)
      return false;

    const { tastings } = this.props;
    newTasting.num = tastings ? (tastings.length + 1) : 1;

    const newTastingsRef = firebase.database().ref('tastings').push();
    newTastingsRef.set(newTasting);
    this.formRef.current.reset();
    this.setState({ left: { countryData: null }, right: { countryData: null } })
  }

  renderItem = (side) => {
    const { data } = this.props;
    const { countryData } = this.state[side]

    return (
      <div className="ct-options">
        <div className="ct-options-row">
          <label>årgang</label>
          <input
            name={`year--${side}`}
            type="number"
          />
          <label>Vin</label>
          <input
            name={`bottle--${side}`}
            className="ct-auto"
          />
        </div>
        { data && (
          <div className="ct-options-row">
            <select name={`country--${side}`} onChange={(e) => this.onCountrySelect(e, side)}>
              <option />
              { data.map((country) => (<option key={country.name}>{ country.name }</option>)) }
            </select>
          { countryData && (
            <select name={`region--${side}`}>
              <option />
              { countryData.regions.map((region) => (<option key={region}>{ region }</option>)) }
            </select>
          )}
          { countryData && (
            <select name={`grape--${side}`}>
              <option />
              { countryData.grapes.map((grape) => (<option key={grape}>{ grape }</option>)) }
            </select>
          )}
          </div>
        )}
        { countryData && (
          <div className="ct-options-row">
            <select name={`producer--${side}`}>
              <option />
              { countryData.producers.map((producer) => (<option key={producer}>{ producer }</option>)) }
            </select>
          </div>
        )}
      </div>
    );
  }

  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }

  render = () => {
    return (
      <form
        ref={this.formRef}
        className="create-tasting"
        onSubmit={this.handleSubmitForm}
        autoComplete="off"
      >
        <div className="ct-side">
          <div className="ct-label">V</div>
          { this.renderItem('left') }
        </div>
        <div className="ct-side ct-side--last">
          <div className="ct-label">H</div>
          { this.renderItem('right') }
        </div>
        <button>Tilføj smagning</button>
      </form>
    )
  }
}